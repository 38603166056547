import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import CustomSidebar from "./components/sidebar/CustomSidebar";
// import CustomNavbar from "./components/CustomNavbar";
import LoginForm from "./components/loginform/LoginForm";
import CallDetails from "./components/calldetails/CallDetails";

import 'bootstrap/dist/css/bootstrap.min.css';


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginForm />} />
        <Route path="/dashboard" element={<CustomSidebar />} />
        <Route path="/calls/:callId" element={<CallDetails />} />
      </Routes>
    </Router>
  );
};

export default App;
