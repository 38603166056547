import React, { useEffect, useState } from "react";
import { Box, Heading, Text, Flex } from "@chakra-ui/react";

import "./Home.css";
import config from "../../config";

export default function Home() {

    const [callSummary, setCallSummary] = useState({
    total_calls: 0,
    Verified: 0,
    Inprogress: 0,
    Inconclusive: 0,
    "No Answer": 0,
    });

  useEffect(() => {
    const fetchCallsSummary = async () => {
      try {
        const requestOptions = {
          method: "GET",
        };
        const response = await fetch(
          `${config.apiDomain}/summary`,
          requestOptions,
        );
          const jsonResponse = await response.json();
          console.log(jsonResponse);
        //   const { Verified, } = jsonResponse
      setCallSummary(jsonResponse);
      } catch (error) {
        console.error("Error fetching client ID:", error);
      }
    };

    fetchCallsSummary();
  }, []);

  return (
    <Box maxW="1200px" mx="auto" p={8}>
      <Heading mb={6}>Calls Summary</Heading>
      <Flex gap={6} flexWrap="wrap">
        <StatCard
          title="Total Calls"
          value={callSummary.total_calls}
          color="blue.500"
        />
        <StatCard
          title="Verified"
          value={callSummary.Verified}
          color="green.500"
        />
        <StatCard
          title="In Progress"
          value={callSummary.Inprogress}
          color="blue.500"
        />
        <StatCard
          title="Inconclusive"
          value={callSummary.Inconclusive}
          color="orange.500"
        />
        <StatCard
          title="No Answer"
          value={callSummary["No Answer"]}
          color="red.500"
        />
      </Flex>
    </Box>
  );

}

function StatCard({ title, value, color }) {
    return (
      <Box
        bg="white"
        _dark={{ bg: "gray.700", borderColor: "gray.600" }}
        p={6}
        rounded="lg"
        flex="1"
        minW="250px"
        shadow="md"
        borderWidth="1px"
        borderColor="gray.200"
      >
        <Text fontSize="lg" color="gray.500" _dark={{ color: "gray.300" }}>
          {title}
        </Text>
        <Text
          fontSize="3xl"
          fontWeight="bold"
          color={color}
          _dark={{ color: `${color.split(".")[0]}.300` }}
        >
          {value.toLocaleString()}
        </Text>
      </Box>
    );
}