import React, { useEffect, useState } from "react";
import { Form, Field, FormLayout, SubmitButton } from "@saas-ui/react";
import {
  Box,
  Heading,
  Text,
  Icon,
  Flex,
  Divider,
  Switch,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { FiPhoneCall } from "react-icons/fi";

import "./CallForm.css";
import config from "../../config";

export default function CallForm() {
  const [clientID, setClientID] = useState(null);
  const [logMessages, setLogMessages] = useState("");

  const [callDetails, setCallDetails] = useState({
    data: undefined,
    email: undefined,
    broker_email: undefined,
    broker_phone: undefined,
    load_number: undefined,
    is_critical: false,
    // deductions: undefined
  });

  useEffect( () => {
    const fetchClientID = async () => {
      try {
        const requestOptions = {
          method: "GET",
        };
        const response = await fetch(`${config.userAPI}/`, requestOptions);
        const jsonResponse = await response.json();
        setClientID(jsonResponse.client_id);
        console.log(jsonResponse.client_id);

      } catch (error) {
        console.error("Error fetching client ID:", error);
      }
    };

    fetchClientID();
  }, []);

  useEffect(() => {
    if (clientID) {
      const source = new EventSource(`${config.userAPI}/stream/${clientID}`);

      source.onmessage = function (event) {
        const now = new Date();
        const formattedDate = now.toLocaleString();
        console.log(event.data)
        setLogMessages(
          (prevLogs) => `${prevLogs}LOG[${formattedDate}]: ${event.data}\n`,
        );
      };

      source.onerror = function (error) {
        console.error("EventSource failed:", error);
      };

      return () => {
        source.close(); // Cleanup on component unmount
      };
    }
  }, [clientID]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCallDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSwitchChange = (e) => {
      setCallDetails((prevState) => ({
        ...prevState,
        is_critical: e.target.checked,
      }));
    console.log(callDetails.is_critical);
  };

  const onSubmit = async (params) => {
    const postData = {
      data: callDetails.data,
      load_number: callDetails.load_number,
      email: callDetails.email,
      broker_email: callDetails.broker_email,
      broker_phone: callDetails.broker_phone,
      // deductions: callDetails.deductions,
      client_id: clientID,
      is_critical: callDetails.is_critical
    };
        console.log(callDetails.is_critical);


    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Specify the content type as JSON
      },
      body: JSON.stringify(postData), // Convert the JavaScript object to JSON string
    };
    console.log(postData);
    await fetch(`${config.userAPI}/call`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  return (
    <div className="form-container">
      <Box className="form-box">
        <Flex alignItems="center" justifyContent="center" mb={4}>
          <Icon as={FiPhoneCall} w={8} h={8} color="teal.500" mr={2} />
          <Heading as="h2" size="lg" textAlign="center">
            Initiate a Call
          </Heading>
        </Flex>
        <Text mb={6} textAlign="center" color="gray.600">
          Please fill out the form below to initiate a call.
        </Text>
        <Divider mb={6} />
        <Form onSubmit={onSubmit}>
          <FormLayout>
            <Field
              name="load_number"
              type="text"
              label="load number"
              placeholder="#123455"
              onChange={handleChange}
              rules={{ required: true }}
            />

            <Field
              name="data"
              label="Data"
              type="textarea"
              placeholder="Enter invoice details here..."
              onChange={handleChange}
              rules={{ required: true }}
            />

            <Field
              name="email"
              type="email"
              label="Your Email"
              placeholder="your.email@example.com"
              onChange={handleChange}
              rules={{ required: true }}
            />

            <Field
              name="broker_email"
              type="email"
              label="Broker's Email"
              placeholder="broker.email@example.com"
              onChange={handleChange}
              rules={{ required: true }}
            />

            <Field
              name="broker_phone"
              type="tel"
              label="Broker's Phone"
              placeholder="+1 (555) 123-4567"
              onChange={handleChange}
              rules={{ required: true }}
            />

            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="critical-switch" mb="0">
                Critical Verification
              </FormLabel>
              <Switch
                id="critical-switch"
                isChecked={callDetails.is_critical}
                onChange={handleSwitchChange}
                colorScheme="teal"
              />
            </FormControl>

            <SubmitButton
              style={{ backgroundColor: "#000000" }}
              className="submit-button"
              mt={4}
            >
              Initiate Call
            </SubmitButton>
          </FormLayout>
        </Form>
        <br></br>
        <p>
          <b>Realtime call logs</b>
        </p>
        <textarea
          value={logMessages}
          readOnly
          style={{ width: "100%", height: "150px" }}
          key={logMessages.length}
        />
      </Box>
    </div>
  );
}
