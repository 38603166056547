import React, { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { FaUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom"; 
import "./LoginForm.css"; 
import PopMessage from "../popmessage/PopMessage"
import config from "../../config";
const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showModal, setShowModal] = useState(false); 
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate(); 

  const closeModal = () => {
    setShowModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("Login submitted:", { email, password });
    try {
      const response = await fetch(`${config.apiDomain}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email, password: password }),
      });

      const data = await response.json();

      if (response.ok) {
        console.log(data);
        localStorage.setItem("token", data.token);
        navigate("/dashboard");
      } else {
        setErrorMessage(data.message || "Login failed. Please try again.");
        setShowModal(true); // Show the modal on error
        console.error("Login failed", data.message);
      }
    } catch (e) {
      console.log(e);
      setErrorMessage("Login failed. Please try again.");
      setShowModal(true); // Show the modal on error
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
      <Card style={{ width: "25rem" }}>
        <Card.Header className="text-center">
          <div className="d-flex align-items-center justify-content-center">
            <FaUser className="me-2" style={{ fontSize: "1.5rem" }} />
            <h2
              style={{
                fontFamily: "'Roboto', sans-serif",
                fontSize: "2rem",
                margin: 0,
              }}
            >
              Login
            </h2>
          </div>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Label style={{ fontFamily: "'Open Sans', sans-serif" }}>
                Email address
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={{
                  fontFamily: "'Open Sans', sans-serif",
                  fontSize: "1rem",
                }}
              />
            </Form.Group>

            <Form.Group controlId="formPassword" className="mb-3">
              <Form.Label style={{ fontFamily: "'Open Sans', sans-serif" }}>
                Password
              </Form.Label>
              <div className="position-relative">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  style={{
                    fontFamily: "'Open Sans', sans-serif",
                    fontSize: "1rem",
                  }}
                />
                <Button
                  variant="link"
                  onClick={() => setShowPassword(!showPassword)}
                  className="position-absolute end-0 top-0 mt-2 me-2"
                >
                  {showPassword ? <EyeSlash /> : <Eye />}
                </Button>
              </div>
            </Form.Group>

            <Button
              variant="dark"
              type="submit"
              className="w-100"
              style={{ fontFamily: "'Roboto', sans-serif" }}
            >
              Log In
            </Button>
            <PopMessage
              message={errorMessage}
              show={showModal}
              handleClose={closeModal}
            ></PopMessage>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default LoginForm;
