import React from "react";
import ReactDOM from "react-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { SaasProvider } from "@saas-ui/react";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ChakraProvider>
    <SaasProvider>
      <App />
    </SaasProvider>
  </ChakraProvider>,
  document.getElementById("root"),
);
