import React, { useState } from "react";
import {
  Spacer,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import {
  AppShell,
  Sidebar,
  SidebarToggleButton,
  SidebarSection,
  NavItem,
  PersonaAvatar,
  Persona,
  PersonaDetails,
  PersonaLabel
} from "@saas-ui/react";
import { FiHome, FiSettings } from "react-icons/fi";
import { SlCallOut } from "react-icons/sl";
import { MdHistory } from "react-icons/md";
import "./CustomSidebar.css"; // Import the CSS file
import CallForm from "../callform/CallForm";
import Home from "../home/Home"
import { useNavigate } from "react-router-dom"; // Import useNavigate
import config from "../../config";
import CallHistory from "../callhistory/CallHistory";
import Settings from "../settingsform/Settings";

const CustomSidebar = (props) => {
  const [activeItem, setActiveItem] = useState("Home");
  const navigate = useNavigate(); 

  const logout = async (e) => {
     await fetch(`${config.apiDomain}/logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
       body: JSON.stringify({ token: localStorage.getItem("token") }),
     }).then((result) => { navigate("/"); }).catch((e) => { 
       console.log(e);
       navigate("/");
     })
  };
    
  const renderContent = () => {
    switch (activeItem) {
      case "Home":
        return <Home/>;
      case "Call Initiation":
        return <CallForm />;
      case "Call History":
        return <CallHistory/>;
      case "Settings":
        return <Settings/>;
      default:
        return <div>Welcome to the Dashboard</div>;
    }
  };

  return (
    <AppShell
      sidebar={
        <Sidebar
          className="custom-sidebar"
          width={["100px", "250px", "300px"]}
          toggleBreakpoint="sm"
        >
          <SidebarToggleButton />
          <SidebarSection direction="row">
            <Menu>
              <MenuButton
                as={IconButton}
                icon={
                  <Persona>
                    <PersonaAvatar
                      presence="online"
                      size="xs"
                      src="/showcase-avatar.jpg"
                      name="Tyler"
                    />
                    <PersonaDetails>
                      <PersonaLabel>Tyler</PersonaLabel>
                    </PersonaDetails>
                  </Persona>
                }
                variant="ghost"
              />
              <MenuList>
                <MenuItem onClick={logout}>Sign out</MenuItem>
              </MenuList>
            </Menu>
            <Spacer />
          </SidebarSection>
          <SidebarSection aria-label="Main">
            <NavItem
              icon={<FiHome />}
              className="custom-nav-item"
              isActive={activeItem === "Home"}
              onClick={() => setActiveItem("Home")}
            >
              Home
            </NavItem>
            <NavItem
              icon={<SlCallOut />}
              className="custom-nav-item"
              isActive={activeItem === "Call Initiation"}
              onClick={() => setActiveItem("Call Initiation")}
            >
              Call Initiation
            </NavItem>
            <NavItem
              icon={<MdHistory />}
              className="custom-nav-item"
              isActive={activeItem === "Call History"}
              onClick={() => setActiveItem("Call History")}
            >
              Call History
            </NavItem>
            <NavItem
              icon={<FiSettings />}
              className="custom-nav-item"
              isActive={activeItem === "Settings"}
              onClick={() => setActiveItem("Settings")}
            >
              Settings
            </NavItem>
          </SidebarSection>
        </Sidebar>
      }
    >
      {renderContent()}
    </AppShell>
  );
};

export default CustomSidebar;
