import React, { useRef } from "react";

const PopMessage = ({ message, show, handleClose }) => {
  const modalRef = useRef(null);

  const closeModal = (e) => {
    if (modalRef.current && e.target === modalRef.current) {
      handleClose();
    }
  };

  return (
    show && (
      <div
        ref={modalRef}
        className="modal"
        onClick={closeModal}
        style={{
          position: "fixed",
          zIndex: "1",
          left: "0",
          top: "0",
          width: "100%",
          height: "100%",
          overflow: "auto",
          backgroundColor: "rgba(0, 0, 0, 0.8)", // Darker background for contrast
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="modal-content"
          style={{
            backgroundColor: "#ffffff", // White background for modal content
            padding: "30px",
            borderRadius: "12px",
            width: "350px",
            textAlign: "center",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Soft shadow for depth
            color: "#333", // Dark text for contrast
            position: "relative",
          }}
        >
        <h2 style={{ fontSize: "22px", marginBottom: "15px", color: "#000" }}>
            Login Failed
          </h2>
          <p style={{ fontSize: "16px", color: "#555" }}>{message}</p>
          <button
            onClick={handleClose}
            style={{
              marginTop: "20px",
              padding: "10px 20px",
              backgroundColor: "#000", // Black button for contrast
              color: "#fff", // White text on button
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              fontSize: "16px",
            }}
          >
            Close
          </button>
        </div>
      </div>
    )
  );
};

export default PopMessage;
